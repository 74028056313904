import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { csrfToken } from '@rails/ujs';
import I18n from '../../i18n/i18n';
import { AppState } from '../../types';

import IconShare from '../icons/iconShareComponent';

interface Props {
  location: string;
}

const ExportButton: FC<Props> = (props: Props) => {
  const planId = useSelector((state: AppState) => state.plan.id);
  const planSpotIds = useSelector((state: AppState) => state.planSpot.ids);
  const rootPath = useSelector((state: AppState) => state.config.rootPath);
  const iframe = useSelector((state: AppState) => state.config.iframe);

  const { location } = props;

  return (
    <form
      className={`jc-u-d-inline-block jc-u-h-100 ${location === 'foot' ? 'jc-u-w-100' : ''}`}
      method="post"
      action={`${rootPath}/exports?plan_id=${planId}`}
      data-remote={iframe}
    >
      <button className={`${location}-utility-btn --color_export`} type="submit" disabled={planSpotIds.length === 0}>
        <IconShare additionalClassNames={['btn__icon']} />
        {I18n.t('japan_concierge.active_plans.show.create_an_itinerary')}
        <input type="hidden" name="authenticity_token" value={csrfToken() || ''} />
      </button>
    </form>
  );
};

export default ExportButton;
