import React, { FC } from 'react';
import { csrfToken } from '@rails/ujs';
import { useSelector } from 'react-redux';
import I18n from '../../i18n/i18n';
import { AppState } from '../../types';

interface PropsType {
  iframe: boolean;
}

const SingOutButton: FC<PropsType> = (props: PropsType) => {
  const { iframe } = props;
  const rootPath = useSelector((state: AppState) => state.config.rootPath);

  if (iframe) {
    return (
      <button
        data-testid="iframe_button"
        type="button"
        className="jc-sign-inout-btn"
        onClick={() => {
          window.parent.postMessage('jc-signout', '*');
        }}
      >
        {I18n.t('japan_concierge.auth_menus.show.sign_out')}
      </button>
    );
  }

  return (
    <form className="jc-sign-inout-btn-container" method="post" action={`${rootPath}/auth`} data-testid="no_iframe">
      <input type="hidden" name="_method" value="delete" />
      <input
        className="jc-sign-inout-btn"
        type="submit"
        value={I18n.t('japan_concierge.auth_menus.show.sign_out')}
      />
      <input type="hidden" name="authenticity_token" value={csrfToken() || ''} />
    </form>
  );
};

export default SingOutButton;
