import React, { FC, useEffect, useRef, useState } from 'react';

import SingOutButton from './signOutButtonComponent';
import { AppState, SocialMediaAuth } from '../../types';
import IconGoogle from '../icons/iconGoogleComponent';
import I18n from '../../i18n/i18n';
import { csrfToken } from '@rails/ujs';
import { useSelector } from 'react-redux';
import IconHeart from '../icons/iconHeartComponent';
import IconList from '../icons/iconListComponent';
import IconTwitter from '../icons/iconTwitterComponent';
import IconExternalLink from '../icons/iconExternalLinkComponent';
import IconHandSwipe from '../icons/iconHandSwipeComponent';

interface PropsType {
  iframe: boolean;
  socialMediaAuth: SocialMediaAuth;
}

const LoginPage: FC<PropsType> = (props: PropsType) => {
  const { iframe, socialMediaAuth } = props;
  const parentUrl = useSelector((state: AppState) => state.sns.parentUrl);
  const isDataRemote = useSelector((state: AppState) => state.sns.isDataRemote);
  const rootPath = useSelector((state: AppState) => state.config.rootPath);

  const [showScrollHint, setShowScrollHint] = useState(false);
  const tableRef = useRef<HTMLTableElement>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const hintStyle = {
    opacity: isAnimating ? 0 : 1,
  };

  const checkScrollable = () => {
    if (tableRef.current) {
      const tableWidth = tableRef.current?.offsetWidth || 0;
      const parentWidth = tableRef.current?.parentElement?.offsetWidth || 0;

      if (tableWidth > parentWidth) {
        setShowScrollHint(true);
      } else {
        setShowScrollHint(false);
      }
    }
  };

  const handleHintClick = () => {
    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
      setShowScrollHint(false);
    }, 300);
  };

  const handleScroll = () => {
    if (tableRef.current && tableRef.current.parentElement) {
      const parent = tableRef.current.parentElement;

      if (parent.scrollLeft > 0 && showScrollHint) {
        setIsAnimating(true);
      }
    }
  };

  useEffect(() => {
    checkScrollable();
    window.addEventListener('resize', checkScrollable);

    const parent = tableRef.current?.parentElement;
    if (parent) {
      parent.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('resize', checkScrollable);

      if (parent) {
        parent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => {
        setIsAnimating(false);
        setShowScrollHint(false);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  if (socialMediaAuth.name) {
    return (
      <div className="favorite-spot-list login-page">
        <div className="login-page__user-name">
          {I18n.t('japan_concierge.active_plans.show.username')}： {socialMediaAuth.name}
        </div>
        <div className="login-page__sing-out-button-container">
          <SingOutButton iframe={iframe} />
        </div>
      </div>
    );
  }

  return (
    // SNS認証ステータス表示（認証済みか否かでdiv内のコンテンツが書き換えられる
    <div className="favorite-spot-list login-page">
      <h4 className="favorite-spot-list__title">
        {I18n.t('japan_concierge.active_plans.show.login_sns_for_convenience')}
      </h4>

      <div className="login-page__sns">
        {/* <form className="button_to login-page__sns-item" method="post" action={`${rootPath}/auth`} data-remote={isDataRemote}>
          <button className="jc-btn-style-reset login-page__sns-button login-page__sns-button--facebook" type="submit">
            <IconFacebook additionalClassNames={['login-page__sns-button-icon']} />
            {I18n.t('japan_concierge.auths.show.sign_in_with_facebook')}
          </button>
          <input type="hidden" name="authenticity_token" value={csrfToken() || ''} />
          <input type="hidden" name="parent_url" value={parentUrl} />
          <input type="hidden" name="provider" value="facebook" />
        </form> */}
        <form
          className="button_to login-page__sns-item"
          method="post"
          action={`${rootPath}/auth`}
          data-remote={isDataRemote}
        >
          <button className="jc-btn-style-reset login-page__sns-button login-page__sns-button--google" type="submit">
            <IconGoogle additionalClassNames={['login-page__sns-button-icon']} />
            {I18n.t('japan_concierge.auths.show.sign_in_with_google')}
          </button>
          <input type="hidden" name="authenticity_token" value={csrfToken() || ''} />
          <input type="hidden" name="parent_url" value={parentUrl} />
          <input type="hidden" name="provider" value="google_oauth2" />
        </form>
        <form
          className="button_to login-page__sns-item"
          method="post"
          action={`${rootPath}/auth`}
          data-remote={isDataRemote}
        >
          <button className="jc-btn-style-reset login-page__sns-button login-page__sns-button--twitter" type="submit">
            <IconTwitter additionalClassNames={['login-page__sns-button-icon']} />
            {I18n.t('japan_concierge.auths.show.sign_in_with_twitter')}
          </button>
          <input type="hidden" name="authenticity_token" value={csrfToken() || ''} />
          <input type="hidden" name="parent_url" value={parentUrl} />
          <input type="hidden" name="provider" value="twitter" />
        </form>
      </div>

      <div className="login-page__table-container" onScroll={handleScroll}>
        <table ref={tableRef} className="login-page__table">
          <thead>
            <tr>
              <th className="login-page__table-th-color"></th>
              <th className="login-page__table-th-color">
                <div className="login-page__table-th-inner">
                  <IconHeart additionalClassNames={['jc-top-menu__icon']} />
                  {I18n.t('japan_concierge.active_plans.show.save_favorites_list')}
                </div>
              </th>
              <th className="login-page__table-th-color">
                <div className="login-page__table-th-inner">
                  <IconList additionalClassNames={['jc-top-menu__icon']} />
                  {I18n.t('japan_concierge.active_plans.show.save_my_plan')}
                </div>
              </th>
              <th className="login-page__table-th-color">
                <div className="login-page__table-th-inner">
                  <IconExternalLink additionalClassNames={['jc-top-menu__icon']} />
                  {I18n.t('japan_concierge.active_plans.show.link_nearby_travel_sites')}
                </div>
                <span className="login-page__table-notes">
                  （{I18n.t('japan_concierge.active_plans.show.add_spots_other_websites_to_your_favorites')}）
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="login-page__table-is-login-color">
                {I18n.t('japan_concierge.active_plans.show.with_login')}
              </th>
              <td className="login-page__table-is-login-color">⚪︎</td>
              <td className="login-page__table-is-login-color">
                <div className="login-page__table-td-inner">
                  <span>⚪︎</span>
                  <span className="login-page__table-notes">
                    ※{I18n.t('japan_concierge.active_plans.show.saved_available_all_devices')}
                  </span>
                </div>
              </td>
              <td className="login-page__table-is-login-color">⚪︎</td>
            </tr>
            <tr>
              <th>{I18n.t('japan_concierge.active_plans.show.no_login')}</th>
              <td>⚪︎</td>
              <td>
                <div className="login-page__table-td-inner">
                  <span>△</span>
                  <span className="login-page__table-notes">
                    ※{I18n.t('japan_concierge.active_plans.show.saved_web_browser')}
                  </span>
                </div>
              </td>
              <td>×</td>
            </tr>
          </tbody>
        </table>
        {showScrollHint && (
          <div className="login-page__scroll-hint" onClick={handleHintClick} style={hintStyle}>
            <IconHandSwipe additionalClassNames={['login-page__scroll-hint-icon']} />
            {I18n.t('japan_concierge.active_plans.show.scrollable_horizontally')}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
