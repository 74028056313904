import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { csrfToken } from '@rails/ujs';
import { AppState } from '../../types';

import IconShare from '../icons/iconShareComponent';
import I18n from '../../i18n/i18n';

const ExportButton: FC = () => {
  const planId = useSelector((state: AppState) => state.plan.id);
  const planSpotIds = useSelector((state: AppState) => state.planSpot.ids);
  const rootPath = useSelector((state: AppState) => state.config.rootPath);
  const iframe = useSelector((state: AppState) => state.config.iframe);

  return (
    <form
      className={`jc-u-d-inline-block jc-u-h-100`}
      method="post"
      action={`${rootPath}/exports?plan_id=${planId}`}
      data-remote={iframe}
    >
      <button className="jc-export-button" type="submit" disabled={planSpotIds.length === 0}>
        <IconShare additionalClassNames={['jc-export-button__icon']} />
        {I18n.t('japan_concierge.active_plans.show.share')}
        <input type="hidden" name="authenticity_token" value={csrfToken() || ''} />
      </button>
    </form>
  );
};

export default ExportButton;
